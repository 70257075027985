/**
 *
 * 通常問題　問題を解く処理
 *
 */

document.addEventListener("turbolinks:load", function () {
  // 問題を解くシーケンス
  if (document.getElementById("regular_qa_sequence")) {
    regular_questions_ux()
    decodeContent()
    feedback_modal()
  }
})

function regular_questions_ux() {
  //「このサイトを離れますか？」警告
  // $(window).on('beforeunload ', function (e) {
  //   e.preventDefault();
  //   return '';
  // });
  //「このサイトを離れますか？」任意ボタンのみ除外
  // $(document).on("submit", "form", function (event) {
  //   $(window).off('beforeunload');
  // });

  $("input:radio").addClass("form-check-input radio_styling")
  $("input:checkbox").addClass("form-check-input cb_styling")

  //全部セレクトをはずす
  // $("input:radio").prop('checked', false);
  // $("input:checkbox").prop('checked', false);

  const paramFound = location.href.match(/#(.*)/)
  let questionNo = paramFound && parseInt(paramFound[1])
  if (paramFound && !isNaN(questionNo) && questionNo <= 10) {
  } else {
    window.location.hash = ""
    questionNo = 1
  }
  if (!paramFound) {
    let found = null
    document.querySelectorAll(".section-explanation:not(.hidden)").forEach((el, i) => {
      found = el
    })
    questionNo = found ? Number(found.dataset.questionIndex) + 1 : 1
  }

  let current = !questionNo || questionNo == NaN ? 0 : questionNo - 1
  const nav = document.querySelector(".question-navigation")
  const questionCount = $(nav).data("question-count") //「5」
  const maxChapterForGuest = $(nav).data("max-chapter-for-guest")
  const questionWrapper = $(".question-wrapper")

  if (current != 0) {
    // アンカーで指定された問題SimpleIDの場所に移動
    jumpQuestion(current)
  } else {
    $(`.question-container:nth-of-type(${current + 1})`).addClass("current")
    // questionWrapper.css("transform", `translate(0), 0)`);
    // TODO: css変数でのスタイル指定に変更
    // questionWrapper.css("--current-question", 0);
    drawPagenation(0)
  }

  /**
   *
   * regular_qa 問題ナビゲーションで状態の更新が必要な箇所
   * - url
   * - 飛び石のカレント <- drawPagenation()内で更新
   * - cardの位置(questionWrapperのcss) <- jumpQuestion()内で更新
   * - questionContainerのcurrent <- jumpQuestion()内で更新
   *
   * 現在位置はcurrent変数が保持
   *
   */

  // 飛び石クリック
  document.querySelectorAll(".reg_pagenation_wrapper .pagenation").forEach((el, i) => {
    el.addEventListener("click", (e) => {
      current = Number(e.target.dataset.questionIndex)
      jumpQuestion(current)
      window.location.hash = current + 1 // html中のhrefは0スタートなのにjsで+1している謎
    })
  })

  //次の問題ボタン(右矢印) &「次の問題へ」ボタン
  const nextButton = $(".next")
  nextButton.on("click", (e) => {
    e.preventDefault()
    const userSignedIn = document.querySelector(".question-container:first-of-type").dataset
      .userSignedIn
    const userPaid = document.querySelector(".question-container:first-of-type").dataset.userPaid
    const userOrganizationPresent = document.querySelector(".question-container:first-of-type")
      .dataset.userOrganizationPresent
    if (current + 1 < questionCount) {
      current++

      jumpQuestion(current)

      //アンカー追加
      window.location.hash = current + 1
    } else {
      const chapterIndex = document.querySelector(".question-wrapper").dataset.chapterIndex
      if (
        (userSignedIn == "false" || (userOrganizationPresent == "false" && userPaid == "false")) &&
        chapterIndex &&
        Number(chapterIndex) >= maxChapterForGuest
      ) {
        // 未ログインの場合次のチャプターにいけない。メッセージを出してもいいかも
        // open paywall modal
        let modalEl = document.querySelector("#paywall-modal")
        let modal = new bootstrap.Modal(modalEl)
        modal.show()
      } else {
        window.location.href = nextButton.data("next-chapter-href")
      }
    }
  })

  //前の問題ボタン(左矢印)
  const prevButton = $(".prev") // このprevおよびnextは名前かえたい TODO
  prevButton.on("click", (e) => {
    e.preventDefault()
    if (current > 0) {
      current--

      jumpQuestion(current)


      //アンカー追加
      window.location.hash = current + 1
    } else {
      // これはfirst element(矢印ボタン)の値をとってきてる

      window.location.href = prevButton.data("prev-chapter-href")
    }
  })

  //キーボード（右、左）で動かす。
  document.addEventListener("keydown", checkKey)
  function checkKey(e) {
    if (document.querySelector(".feedback-modal.show")) {
      // ご意見箱モーダルがあったら動く必要ないのでアーリーリターン
      return
    }

    e = e || window.event
    //右キー
    if (e.keyCode == "39") {
      if (current + 1 < questionCount) {
        current++

        jumpQuestion(current)

        //アンカー追加
        window.location.hash = current + 1
      } else {
        // #240 章を飛び越えて左右キー長押しできないようにする. F5対策
        // window.location.href = nextButton.data("next-chapter-href");
      }
    }
    //左キー
    else if (e.keyCode == "37") {
      if (current > 0) {
        current--

        jumpQuestion(current)

        //アンカー追加
        window.location.hash = current + 1
      } else {
        // #240 章を飛び越えて左右キー長押しできないようにする. F5対策
        // window.location.href = prevButton.data("prev-chapter");
      }
    }
  }

  //ブックマーク
  $(`.button-bookmark`).on("click", (e) => {
    let questionId = $(e.currentTarget).data("question-id")
    let certificationShortName = $(e.currentTarget).data("certification-short-name")
    let book_mark_info = $(`#bookmark_${certificationShortName}_${questionId}`)
    if (book_mark_info.hasClass("is-active")) {
      book_mark_info.removeClass("is-active")
      document.cookie = `bookmark_${certificationShortName}_${questionId}=0;path=/;`
      book_mark_info.html(
        `<span class='innerClass bookmark_q_${questionId}'><span class='material-icons-outlined'>bookmark_border</span>ブックマーク</span>`
      )
    } else {
      book_mark_info.toggleClass("is-active")
      document.cookie = `bookmark_${certificationShortName}_${questionId}=1;path=/;`
      book_mark_info.html(
        `<span class='innerClass is-active bookmark_q_${questionId}'><span class='material-icons-outlined'>bookmark</span>ブックマーク</span>`
      )
    }
  })

  //checkboxかradioで違うクラスの付与
  //　まずはradioから
  // $(`.quiz-choice-wrapper input:radio`).on("click", (e) => {
  //   const target = $(e.currentTarget);
  //   const index = target.parent().data("question-index");
  //   console.log(index);

  //   //radioなのでひとつだけactiveが欲しい。
  //   $(`.quiz-choice-wrapper[data-question-index="${index}"] .quiz-choice`).removeClass('selected');
  //   //で先ほどクリックしたものをハイライト
  //   target.parent().addClass('selected');
  // });

  // //　まずはcheckboxから
  // $(`.quiz-choice-wrapper input:checkbox`).on("click", (e) => {
  //   const target = $(e.currentTarget);
  //   const index = target.parent().data("question-index");
  //   console.log(index);

  //   //$(`.quiz-choice-wrapper[data-question-index="${index}"] .quiz-choice`).removeClass('selected');
  //   //で先ほどクリックしたものをハイライト
  //   target.parent().addClass('selected');
  // });

  const response = {}
  //クリックで答えを表示する

  $(".quiz-choice").on("click", (e) => {
    //e.preventDefault();
    const target = $(e.currentTarget)
    const index = target.data("question-index")
    const userSignedIn = target.closest(".question-container").data("user-signed-in")

    //disabled: trueの場合、これ以降の処理をスキップする
    if (
      $(
        `.quiz-choice[data-question-index="${index}"] input:checkbox, .quiz-choice[data-question-index="${index}"] input:radio`
      )
        .eq(0)
        .prop("disabled")
    ) {
      return
    }

    //複数選択式の場合、2問選択するまでこれ以降の処理をスキップする
    const labelFor = document.getElementById(e.target.htmlFor)
    if ((labelFor && labelFor.type == "checkbox") || e.target.type == "checkbox") {
      let correctCount = $(
        `.quiz-choice-wrapper[data-question-index="${index}"] input[type="hidden"][name="done[question_correct_count]"]`
      ).val()
      if (
        $(`.quiz-choice[data-question-index="${index}"] input:checkbox:checked`).length <
        Number(correctCount)
      ) {
        // 選択済みをハイライトするためのクラスを追加/削除
        if (e.target.checked === true) {
          e.currentTarget.classList.add("selected")
        } else if (e.target.checked === false) {
          e.currentTarget.classList.remove("selected")
        }
        return
      }
    }

    //回答が未記録の設問でクリック時処理を行う．
    //response[index] = target.data("choice-index");

    // userがセレクトしたものをget
    //------------------------------------------------------------

    var userAnswers = {}
    userAnswers.selected = []

    $(`.quiz-choice[data-question-index="${index}"] input:checkbox`).each(function () {
      if ($(this).is(":checked")) {
        userAnswers.selected.push(Number($(this).val()))
      }
    })
    $(`.quiz-choice[data-question-index="${index}"] input:radio`).each(function () {
      if ($(this).is(":checked")) {
        userAnswers.selected.push(Number($(this).val()))
      }
    })

    if (userAnswers.selected.length == 0) {
      // 選択したものがとれてないので以下で絶対失敗する。リクエスト自体おくらない
      // リクエストのエラー「このときなぜか保存できてない」の原因のはず
      return
    }

    //arrayを比較する
    //https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
    //------------------------------

    if (Array.prototype.equals)
      console.warn(
        "Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code."
      )
    // attach the .equals method to Array's prototype to call it on any array
    Array.prototype.equals = function (array) {
      // if the other array is a falsy value, return
      if (!array) return false

      // compare lengths - can save a lot of time
      if (this.length != array.length) return false

      for (var i = 0, l = this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
          // recurse into the nested arrays
          if (!this[i].equals(array[i])) return false
        } else if (this[i] != array[i]) {
          // Warning - two different object instances will never be equal: {x:20} != {x:20}
          return false
        }
      }
      return true
    }
    // Hide method from for-in loops
    Object.defineProperty(Array.prototype, "equals", { enumerable: false })

    //------------------------------
    // そもそも答えは
    var ansObj = {}
    ansObj.correctAnswers = []
    ansObj.wrongAnswers = []
    $(`.quiz-choice[data-question-index="${index}"]`).each(function () {
      if ($(this).data("answer")) {
        ansObj.correctAnswers.push($(this).data("choice-id"))
      } else {
        ansObj.wrongAnswers.push($(this).data("choice-id"))
      }
    })

    // 非ログインの場合回答結果を送らない TODO クッキーに保存とか
    if (!userSignedIn) {
      // console.log(JSON.stringify(ansObj) + " " + JSON.stringify(userAnswers));
      regularQuestionShowAnswer(ansObj, userAnswers, target, index)
      return true
    }

    // console.log(JSON.stringify("question-id=" + target.data("question-id")));
    // console.log(JSON.stringify(userAnswers));
    // 解いた結果を送信
    $.ajax({
      type: "POST",
      data: {
        done: {
          dummy: "",
          selected_choices: userAnswers.selected,
        },
        question_id: target.data("question-id"),
      },
      // contentType: "application/json",
      url: location.pathname + "/question_done",
      success: function (data, textStatus, jqXHR) {
        console.log(
          `success done ${location.href} ${JSON.stringify(userAnswers)} ${JSON.stringify(data)}`
        )
        regularQuestionShowAnswer(ansObj, userAnswers, target, index)
      },
      error: function (jqXHR, textStatus, errorThrown) {
      },
    }) // end ajax

    // $('#reg_submit').click();
  }) // end click

  document.querySelectorAll(`.refresh_button`).forEach((element) => {
    const userSignedIn = document.querySelector(".question-container:first-of-type").dataset
      .userSignedIn
    // console.log(`userSignedIn: ${userSignedIn}`);
    const index = Number(element.dataset.questionIndex || -1)
    if (index === -1) return

    if (userSignedIn == "false") {
      document
        .querySelector(`.refresh_button[data-question-index="${index}"]`)
        .addEventListener("click", function (e) {
          regularQuestionRollback(index)
        })
    } else {
      document
        .querySelector(`.refresh_button[data-question-index="${index}"]`)
        .addEventListener("click", (event) => {
          event.preventDefault()
          const target = event.currentTarget
          const questionId = target.dataset.questionId
          // 解いた結果をDBから削除
          $.ajax({
            type: "DELETE",
            data: {
              question_id: Number(questionId),
            },
            url: location.pathname + "/question_undo",
            success: function (data, textStatus, jqXHR) {
              regularQuestionRollback(index)

              //   // alert('hoge');
              // // スクロールリセット
              window.scroll({ top: 0, behavior: "instant" })
            },
          }) // end ajax
        })
    }
  })
} // regular_questions_ux

/**
 * 解答解説などを表示する。(過去に回答済みのものはview内で直接スタイル指定した状態で表示されるため、ページロード時点ではこの関数は呼び出されない。)
 */
function regularQuestionShowAnswer(ansObj, userAnswers, target, index) {
  $(`.quiz-choice[data-question-index="${index}"]`).addClass("show-answer")
  $(`.section-explanation[data-question-index="${index}"]`).removeClass("hidden")
  const addClassName = target.data("answer") ? "correct" : "incorrect"
  $(`.pagenation[data-question-index="${index}"]`).addClass(addClassName)

  // userの答えとそもそもの問題の答えを比較。
  //------------------------------------------------------------
  if (ansObj.correctAnswers.equals(userAnswers.selected)) {
    //true
    document
      .querySelector(`.quiz-choice-wrapper[data-question-index="${index}"]`)
      .classList.add("correct")
    document
      .querySelector(`.section-explanation[data-question-index="${index}"]`)
      .classList.add("correct")
    document.querySelector(
      `.section-explanation[data-question-index="${index}"] .result_callout`
    ).innerHTML = "正解"
  } else {
    //false
    document
      .querySelector(`.quiz-choice-wrapper[data-question-index="${index}"]`)
      .classList.add("wrong")
    document
      .querySelector(`.section-explanation[data-question-index="${index}"]`)
      .classList.add("wrong")
    document.querySelector(
      `.section-explanation[data-question-index="${index}"] .result_callout`
    ).innerHTML = "不正解"
  }

  // 正解だろうが不正解だろうが表示するもの 解きなおすボタンとフィードバック
  document
    .querySelector(`.question-footer[data-question-index="${index}"]`)
    .classList.remove("hidden")
  document
    .querySelectorAll(`.js-section-keyword[data-question-index="${index}"]`)
    .forEach((element) => {
      element.classList.remove("hidden")
    })

  // 回答した問題の選択肢を変更不可にする
  $(
    `.quiz-choice[data-question-index="${index}"] input:checkbox, .quiz-choice[data-question-index="${index}"] input:radio`
  ).each(function () {
    $(this).prop("disabled", true)
  })
}

function regularQuestionRollback(index) {
  // もとに戻す処理

  document
    .querySelector(`.quiz-choice-wrapper[data-question-index="${index}"]`)
    .classList.remove("correct", "wrong")
  document
    .querySelector(`.section-explanation[data-question-index="${index}"]`)
    .classList.remove("correct", "wrong")
  document.querySelector(
    `.section-explanation[data-question-index="${index}"] .result_callout`
  ).innerHTML = ""

  document.querySelectorAll(`.quiz-choice[data-question-index="${index}"]`).forEach((el) => {
    el.classList.remove("show-answer")
    el.classList.remove("selected")
  })
  document
    .querySelector(`.section-explanation[data-question-index="${index}"]`)
    .classList.add("hidden")
  document.querySelector(`.pagenation[data-question-index="${index}"]`).classList.remove("answered")

  document.querySelector(`.question-footer[data-question-index="${index}"]`).classList.add("hidden")

  document
    .querySelectorAll(`.js-section-keyword[data-question-index="${index}"]`)
    .forEach((element) => {
      element.classList.add("hidden")
    })

  // document
  //   .querySelector(`.question-feedback[data-question-index="${index}"]`)
  //   .classList.add("hidden");

  // 以下進むと戻るは常に表示になったので hidden をつけない
  // document
  //   .querySelector(`.question-footer[data-question-index="${index}"]`)
  //   .classList.add("hidden");
  // document.querySelector(
  //   `.question-footer[data-question-index="${index}"]`
  // ).dataset.done = "false";
  // $(`.reg_submit[data-question-index="${index}"]`).removeClass("clicked");

  document
    .querySelectorAll(`.quiz-choice[data-question-index="${index}"] input`)
    .forEach(function (element) {
      element.checked = false
    })

  // TODO document で書き換え
  $(
    `.quiz-choice[data-question-index="${index}"] input:checkbox, .quiz-choice[data-question-index="${index}"] input:radio`
  ).each(function () {
    $(this).prop("disabled", false)
  })
}

function jumpQuestion(current) {
  const questionWrapper = $(".question-wrapper")

  // if (questionWrapper.css("left")) {
  //   questionWrapper.css("left", "");
  // }
  questionWrapper.css("--current-question", current)
  // スクロールリセット
  window.scroll({ top: 0, behavior: "instant" })

  $(".question-container.current").removeClass("current")
  $(`.question-container:nth-of-type(${current + 1})`).addClass("current")

  drawPagenation(current)
}

/**
 * currentに合わせてページナビゲーションを更新する(色をつける)
 * @param {number} current
 */
function drawPagenation(current) {
  // 飛び石のページナビゲーションのところ
  document.querySelectorAll(".pagenation").forEach(function (el, i) {
    if (i === current) {
      el.classList.add("current")
    } else {
      el.classList.remove("current")
    }
    const index = el.dataset.questionIndex
    // 解いてるかどうかの取得。説明にhiddenがついてないかしか見る方法がない
    // いや、new_doneにもついている
    const expEl = document.querySelector(`.section-explanation[data-question-index="${index}"]`)
    if (expEl && !expEl.classList.contains("hidden")) {
      // 解いてる
      el.classList.add("answered")
    }
  })
}

function decodeContent() {
  document.querySelectorAll(".question-content").forEach(function (el, i) {
    el.innerHTML = decodeURIComponent(escape(window.atob(el.innerHTML)))
    el.classList.remove("d-none")
  })
  document.querySelectorAll(".choice-explanation").forEach(function (el, i) {
    el.innerHTML = decodeURIComponent(escape(window.atob(el.innerHTML)))
    el.classList.remove("d-none")
  })
}

// FIXME: DOMの構造を見直したほうがいい(label自体にカードスタイルをつければスタイル切り替えにjs不要)
function feedback_modal() {
  document.querySelectorAll(".feedback-choice").forEach((el) => {
    el.addEventListener("click", () => {
      if (!el.classList.contains("selected")) {
        // input自体の選択切り替え
        el.querySelector("input").checked = true

        // .feedback-choice(li)のスタイル切り替え
        document
          .querySelectorAll('[data-js-feedback-choice-id="' + el.dataset.jsFeedbackChoiceId + '"]')
          .forEach((choice) => {
            choice.classList.toggle("selected")
          })
      }
    })
  })
}
