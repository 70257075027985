document.addEventListener('DOMContentLoaded', () => {
  const addCopyButtons = (container) => {
    const codeBlocks = container.querySelectorAll('pre');
    
    codeBlocks.forEach(block => {
      const copyButton = document.createElement('button');
      copyButton.className = 'copy-button';
      copyButton.textContent = 'content_copy';
      copyButton.setAttribute('data-tooltip', 'コピーする');

      block.appendChild(copyButton);

      copyButton.addEventListener('click', async () => {
        try {
          await navigator.clipboard.writeText(block.textContent.replace('content_copy', '').trim());
          copyButton.setAttribute('data-tooltip', 'コピーしました！');
          
          setTimeout(() => {
            copyButton.setAttribute('data-tooltip', 'コピーする');
          }, 2000);
        } catch (err) {
          console.error('Failed to copy text: ', err);
          copyButton.setAttribute('data-tooltip', 'コピーに失敗しました');
          setTimeout(() => {
            copyButton.setAttribute('data-tooltip', 'コピーする');
          }, 2000);
        }
      });
    });
  };

  // 初期ロード時に既存のコードブロックにボタンを追加
  document.querySelectorAll('.textbook-content-base, #hands_on_main_view').forEach(container => {
    addCopyButtons(container);
  });

  // DOMの変更を監視して、新しく追加されたコードブロックにもボタンを追加
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList' && 
          (mutation.target.matches('.textbook-content-base') || 
           mutation.target.matches('#hands_on_main_view'))) {
        addCopyButtons(mutation.target);
      }
    });
  });

  document.querySelectorAll('.textbook-content-base, #hands_on_main_view').forEach(container => {
    observer.observe(container, {
      childList: true,
      subtree: true
    });
  });
});
