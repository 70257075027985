require("flatpickr")
const Japanese = require("flatpickr/dist/l10n/ja.js").default.ja

// 資格詳細ページ
document.addEventListener("turbolinks:load", function () {
  if (document.getElementById("content_cert_list")) {
    // content_cert_list 自体は資格詳細だけでなくmypageにも存在するので注意
    goal_date()
    certification_detail_interaction()
    bookmark_interaction()
    confirm_admin_modal()
    restore_opened_domains()
    textbook_modal()
    textbook_arrowkey()
    show_bookmark_manually()
    mobile_textbook_chapters()
  }
})

function goal_date() {
  const jsDateInstruction = document.getElementById("exam-due-date")
  flatpickr("#personal-goal-date", {
    dateFormat: "Y-m-d",
    clickOpens: false,
    position: "auto center",
    positionElement: jsDateInstruction,
    locale: Japanese,
    minDate: "today",
  })

  function showRemainDate(goalDateString) {
    let tYear, tMonth, tDay
    ;[tYear, tMonth, tDay] = goalDateString.split("-")
    if (tYear == undefined || tYear == "") {
      $(`.js-goal-remain-day`).text("")
      document.getElementById(`jsDateLabel`).classList.remove("d-flex")
      document.getElementById(`jsDateInstruction`).classList.remove("d-none")
      return false
    }

    // 現在日時を数値に変換
    let nowDate = new Date()
    let dnumNow = nowDate.getTime()

    // 指定日時を数値に変換
    let goalDate = new Date(tYear, tMonth - 1, tDay) // 月の値は 1 を引く必要がある点に注意。(※1月は0、2月は1、……12月は11)
    let dnumGoal = goalDate.getTime()

    // 引き算して残日数を計算
    let diffMSec = dnumGoal - dnumNow
    let diffDays = diffMSec / (1000 * 60 * 60 * 24)
    let remainDays = Math.ceil(diffDays) // 小数点以下を切り上げる

    let remainDayText
    if (remainDays >= 0) {
      remainDayText = remainDays + "日"
    } else {
      remainDayText = remainDays * -1 + "日前"
    }

    // 表示
    $(`.js-goal-remain-day`).text(remainDayText)

    return false
  }
  function convertDate(goalDateString) {
    let tYear, tMonth, tDay
    ;[tYear, tMonth, tDay] = goalDateString.split("-")
    let time = tYear + "年" + tMonth + "月" + tDay + "日"
    // alert(time);
    if (tYear) {
      // $("#dateConverted").text(time) 日付表示をしなくなったのでコメントアウト
    } else {
      return
    }
  }

  const defaultGoalDate = $(`.personal-goal-date`).prop("defaultValue")
  if (defaultGoalDate !== null && defaultGoalDate !== undefined && defaultGoalDate !== "") {
    showRemainDate(defaultGoalDate)
  }

  //----- TEST #1
  function checkIfValueExists() {
    if ($("#personal-goal-date").val()) {
      //----- 日付けがpickされた後
      $("#exam-due-date").addClass("picked")
    } else {
      $("#exam-due-date").removeClass("picked")
      //----- 日付けが設定されてない時の表示
      // var rect = document.getElementById("personal-goal-date").getBoundingClientRect()

      // $("#dateConverted").text("") 日付表示をしなくなったのでコメントアウト
    }
  }

  checkIfValueExists()
  let goalDateEle = $("#personal-goal-date")
  if (goalDateEle.val()) {
    convertDate(goalDateEle.val())
    showRemainDate(goalDateEle.val())
  }

  //----- Hover Action

  function hoverAction() {
    $(".js-show-datepick").on("click", (e) => {
      const inputDateElement = document.getElementById("personal-goal-date")
      // inputDateElement.showPicker() // この人はsafariでは動かない
      inputDateElement._flatpickr.open()
    })
  }
  hoverAction()

  //-----

  $(`.personal-goal-date`).on("change", (e) => {
    const currentUserId = $(`.personal-goal-date`).data("currentUserId")
    const certificationId = $(`.personal-goal-date`).data("certificationId")
    const certificationShortName = $(`.personal-goal-date`).data("certificationShortName")
    if (
      currentUserId !== null &&
      currentUserId !== undefined &&
      currentUserId !== "" &&
      e.target.value.length == 10 //2022-09-15
    ) {
      $.ajax({
        type: "POST",
        data: {
          goal_date: e.target.value,
          user_id: currentUserId,
          certification_id: certificationId,
        },
        url: `${certificationShortName}/goal_date`,
        // success: function () {
        // }
      })
    } else if (
      currentUserId !== null &&
      currentUserId !== undefined &&
      currentUserId !== "" &&
      e.target.value.length == 0 //カレンダーの「削除」ボタンクリック
    ) {
      $.ajax({
        type: "POST",
        data: {
          goal_date: null,
          user_id: currentUserId,
          certification_id: certificationId,
        },
        url: `${certificationShortName}/goal_date`,
        // success: function () {
        // }
      })
    }
    convertDate(e.target.value)

    setTimeout(() => {
      checkIfValueExists()
      showRemainDate(e.target.value)
    }, "1")
    //checkIfValueExists();
  })
}

function certification_detail_interaction() {
  $(".primary_info_wrapper").click(function () {
    const parent = $(this).parent()
    parent.toggleClass("closed")
    const domainId = parent.data("domain-id")
    const closed = parent.hasClass("closed")
    save_domain_opened_state(domainId, !closed)
  })
  $(".category_callout").click(function () {
    $(this).parent().toggleClass("closed")
  })

  //資格詳細タブ維持
  let urlHash = location.hash //アンカー取得
  let certShortName = location.pathname.split("/")[1]

  //タブのクッキー(資格別)があれば タブ切り替え変数上書き
  // if (document.cookie != "") {
  //   let tmp = document.cookie.split(";");
  //   for (let i = 0; i < tmp.length; i++) {
  //     let data = tmp[i].split("=");
  //     if (data[0].trim() == `urlHash`) {
  //       urlHash = data[1];
  //       console.log("urlHash override", urlHash);
  //     }
  //   }
  // }

  if (urlHash == "") {
    $("#cert_list").parent().addClass("menu-active")
    $("#content_cert_list").removeClass("not_applicable")
  } else {
    // if (urlHash == `#b`) {
    //   $("#bookmark").parent().addClass("menu-active")
    //   $("#cert_bookmark_list").removeClass("not_applicable")
    // }
    if (urlHash == `#q`) {
      $("#cert_list").parent().addClass("menu-active")
      $("#content_cert_list").removeClass("not_applicable")
    }
    if (urlHash == `#e`) {
      $("#demo_exam").parent().addClass("menu-active")
      $("#cert_practice_exam_list").removeClass("not_applicable")
    }
    if (urlHash == `#t`) {
      $("#textbook").parent().addClass("menu-active")
      $("#cert_textbook_list").removeClass("not_applicable")
      const queryParam = location.search
      const params = new URLSearchParams(queryParam)
      const chapterId = params.get("csid")
      const textbookToken = params.get("textbook_token")
      if (chapterId && isFinite(Number(chapterId)) && textbookToken) {
        // let contentEl = document.getElementById("js-textbook-content-base-" + textbookChapterId)
        // setTextbookContentFromAPI(contentEl, textbookToken, textbookChapterId, textbookArticleId)
        const myModal = new bootstrap.Modal(document.getElementById("textbook-modal-" + chapterId))
        myModal.show()
        window.history.replaceState(null, null, location.pathname)
        setTextbookContentFromAPI(
          document.getElementById("js-textbook-content-base-" + chapterId),
          textbookToken,
          chapterId,
          1
        )
      }
    }
    if (urlHash == `#s`) {
      $("#study_group").parent().addClass("menu-active")
      $("#cert_study_group_list").removeClass("not_applicable")
    }
  }

  $(".line").click(function () {
    //alert($(this).index());
    $(".line").removeClass("menu-active")
    $(this).toggleClass("menu-active")
    $(".cert_content_wrapper").addClass("not_applicable")
    var nth_catcher = $(this).index()
    // if (nth_catcher == 1) {
    //   // self.location.href = "#b";
    //   // document.cookie = `urlHash=#b; path=/${certShortName};`;
    //   $("#cert_bookmark_list").removeClass("not_applicable")
    // } else
    if (nth_catcher == 0) {
      //self.location.href = "";
      // document.cookie = `urlHash=#q; path=/${certShortName};`;
      $("#content_cert_list").removeClass("not_applicable")
    } else if (nth_catcher == 1) {
      // self.location.href = "#e";
      // document.cookie = `urlHash=#e; path=/${certShortName};`;
      $("#cert_practice_exam_list").removeClass("not_applicable")
    } else if (nth_catcher == 2) {
      // self.location.href = "#b";
      // document.cookie = `urlHash=#l; path=/${certShortName};`;
      $("#cert_textbook_list").removeClass("not_applicable")
    } else if (nth_catcher == 4) {
      //メモ：仕切り線あるので「4」がない
      // self.location.href = "#e";
      // document.cookie = `urlHash=#e; path=/${certShortName};`;
      $("#cert_study_group_list").removeClass("not_applicable")
    } else {
      return
    }
  })
}

function bookmark_interaction() {
  //ブックマーク
  $(`.button-bookmark`).on("click", (e) => {
    e.preventDefault()
    let questionId = $(e.currentTarget).data("question-id")
    let certificationShortName = $(e.currentTarget).data("certification-short-name")
    let book_mark_info = $(`#bookmark_${certificationShortName}_${questionId}`)
    if (book_mark_info.hasClass("is-active")) {
      book_mark_info.removeClass("is-active")
      document.cookie = `bookmark_${certificationShortName}_${questionId}=0;path=/;`
      book_mark_info.html(`<span class='innerClass'></span>`)
    }
    // else {
    //   book_mark_info.toggleClass("is-active");
    //   document.cookie = `bookmark_${certificationShortName}_${questionId}=1;path=/;`;
    //   book_mark_info.html(`<span class='innerClass is-active bookmark_q_${questionId}'><span class='material-icons'>bookmark</span></span>`);
    // }
  })
}

function confirm_admin_modal() {
  // document.querySelectorAll(".confirm-admin-certification-modal.show").forEach((el) => {
  //   let modal = new bootstrap.Modal(el);
  //   console.log("modal hide!");
  //   modal.hide();
  // });

  // 戻ったときにこのモーダルが残ったままだったりするので消しておく
  let modalEl = document.getElementById("confirm-admin-certification-modal")
  if (modalEl) {
    modalEl.addEventListener("show.bs.modal", (e) => {
      let formEl = document.getElementById("confirm-admin-certification-modal-form")
      let modal = bootstrap.Modal.getInstance(modalEl)
      formEl &&
        formEl.addEventListener("ajax:success", (e) => {
          // console.log("modal hide");
          modal.dispose()
        })
    })
  }
}

function save_domain_opened_state(id, opened) {
  const stored = localStorage.getItem("opened_domains")
  const openedDomains = stored ? JSON.parse(stored) : {}
  if (!openedDomains[id] || openedDomains[id] !== opened) {
    openedDomains[id] = opened
  }
  localStorage.setItem("opened_domains", JSON.stringify(openedDomains))
}

function restore_opened_domains() {
  /*
  view側では続きのドメイン(or　なければ最初のドメイン)がオープン状態でレンダリングされる
  ここではそれに加えてユーザが手動で開いたドメインをlocalstorageから復元する。
  */

  const stored = localStorage.getItem("opened_domains")
  if (!stored) {
    return
  }

  const openedDomains = JSON.parse(stored)

  document.querySelectorAll(".consolidated_by_category").forEach((el) => {
    const id = el.dataset.domainId
    if (!id) {
      return
    }
    if (openedDomains[id]) {
      el.classList.remove("closed")
    }
  })
}

function textbook_modal() {
  // 最初開いたときに ajax でとってきて埋める君
  // なお、最初に開くときは textbook.js.erb にも処理があるのでそちらも参照のこと
  document.querySelectorAll(".js-textbook-modal-button").forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault()
      const linkEl = e.target.closest(".js-textbook-modal-button")
      const textbookToken = linkEl.dataset.textbookToken
      const chapterId = linkEl.dataset.textbookChapterId
      const textbookArticleId = linkEl.dataset.textbookArticleId

      let contentEl = document.getElementById("js-textbook-content-base-" + chapterId)
      let modalContentEl = contentEl.closest(".modal-content")
      doneTextbookContent(modalContentEl, textbookToken, chapterId, textbookArticleId)
    })
  })

  document.querySelectorAll(".js-textbook-nav-step-item").forEach((el) => {
    el.addEventListener("click", () => {
      const textbookToken = el.dataset.textbookToken
      const chapterId = el.dataset.textbookChapterId
      const textbookArticleId = el.dataset.textbookArticleId

      let contentEl = document.getElementById("js-textbook-content-base-" + chapterId)
      setTextbookContentFromAPI(contentEl, textbookToken, chapterId, textbookArticleId)

      let modalContentEl = contentEl.closest(".modal-content")
      doneTextbookContent(modalContentEl, textbookToken, chapterId, textbookArticleId)
    })
  })
}

function doneTextbookContent(modalContentEl, textbookToken, chapterId, textbookArticleId) {
  fetch(location.pathname + `/t/${textbookToken}/${chapterId}/a/${textbookArticleId}/done`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
    body: JSON.stringify({
      textbook_token: textbookToken,
      chapter_id: chapterId,
      article_id: textbookArticleId,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok.")
      }
      let doneExistsMap = {}

      // 対象のラベルにスタイルをつける
      modalContentEl
        .querySelectorAll(".js-textbook-nav-step-item:not(.js-textbook-footer-link)")
        .forEach((el, i) => {
          if (
            el.dataset.textbookArticleId == textbookArticleId &&
            el.dataset.textbookChapterId == chapterId // datasetはstringで入ってくるので注意
          ) {
            el.classList.add("done")
            el.querySelector(".textbook-nav-check").classList.remove("material-icons-outlined")
            el.querySelector(".textbook-nav-check").classList.add("material-icons")
          }
          if (el.classList.contains("done")) {
            doneExistsMap[i] = true
          }
        })
      // 次のDoneをみてLastDoneを消したり、つける処理をしないといけない！
      modalContentEl
        .querySelectorAll(".js-textbook-nav-step-item:not(.js-textbook-footer-link)")
        .forEach((el, i) => {
          if (el.classList.contains("last-done")) {
            el.classList.remove("last-done")
          }
          if (el.classList.contains("done") && !doneExistsMap[i + 1]) {
            el.classList.add("last-done")
          }
        })
      // 全部のラベルが done ならば、チャプターのラベルにもスタイルをつける
      let allDoneLabel = document.querySelector(
        `.js-chapter-all-check-circle[data-textbook-chapter-id="${chapterId}"]`
      )
      let allDone = true
      modalContentEl
        .querySelectorAll(".js-textbook-nav-step-item:not(.js-textbook-footer-link)")
        .forEach((el) => {
          if (el.dataset.textbookChapterId === chapterId && !el.classList.contains("done")) {
            // console.log(
            //   "el? done",
            //   el.dataset.textbookChapterId,
            //   el.dataset.textbookSectionId,
            //   el.dataset.textbookArticleId,
            //   el.classList.contains("done")
            // )
            allDone = false
          }
        })
      if (allDone) {
        // allDoneLabel.style.color = "#4ECAFE"
        allDoneLabel.innerHTML = `<span class="material-icons" style="color: #4ECAFE;margin-right:8px;">check_circle</span>`
        let chapCardEl = document.querySelector(
          ".js-textbook-modal-button[data-textbook-chapter-id='" + chapterId + "']"
        )
        chapCardEl.querySelector(".js-textbook-callout").classList.add("done")
        let iconEl = chapCardEl.querySelector(".js-textbook-icon")
        iconEl.innerHTML = `<span class="material-icons" style="color: #4ECAFE;font-size: 16px;line-height: 150%;">check_circle</span>`
      }
    })
    .catch((error) => {
    })
}

function setTextbookContentFromAPI(contentEl, textbookToken, chapterId, articleId) {
  fetch(location.pathname + `/t/${textbookToken}/${chapterId}/a/${articleId}/html`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
    credentials: "same-origin",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      return response.json()
    })
    .then((json) => {
      // document.getElementById("js-textbook-ul").innerHTML = json.list_html

      // 対象のラベルの箇所にスタイルをつける
      document
        .querySelectorAll(".js-textbook-nav-step-item:not(.textbook-footer-link)") // フッターもnav-step-itemがついてる
        .forEach((el) => {
          if (
            el.dataset.textbookToken === textbookToken &&
            el.dataset.textbookArticleId === articleId &&
            el.dataset.textbookChapterId === chapterId
          ) {
            el.classList.add("current")
            el.querySelector(".keyboard_arrow_right").classList.remove("invisible")
          } else {
            // ここで別のチャプターのラベルについてもcurrentを外している説ある
            el.classList.remove("current")
            el.querySelector(".keyboard_arrow_right").classList.add("invisible")
          }
        })

      // フッターの前、後のリンクにattrをつける
      handleFooterData(chapterId, json) // 最初のときはテンプレートで埋めてある

      var scrim = document.querySelector(".mobile_chapter_trigger_scrim")
      var navStepsElement = document.querySelector(".textbook-nav-steps")

      //navStepsElement.classList.remove("mct_open")
      //scrim.classList.remove("mct_open")

      document
        .querySelectorAll(".mobile_chapter_trigger_scrim, .textbook-nav-steps")
        .forEach((element) => {
          element.classList.remove("mct_open")
        })

      // ふんわり切り替えの実装
      contentWrapperEl = contentEl.parentElement
      setTimeout(() => {
        // 消す側
        contentWrapperEl.style.opacity = 0
      }, 200)
      setTimeout(() => {
        // 表示する側
        contentWrapperEl.style.opacity = 1
        contentEl.innerHTML = json.content_html
        contentWrapperEl.classList.add("textbook-fade-in")
        //document.querySelector(".textbook-primary-textarea").scrollIntoView()
        //document.querySelector(".modal-content").scrollIntoView()
        document
          .querySelectorAll(".textbook-primary-textarea, .modal-content")
          .forEach((element) => {
            element.scrollIntoView()
            element.scrollTop = 0
          })

      }, 400)
    })
}

function handleFooterData(chapterId, json) {
  let leftEl = document.getElementById(`js-textbook-footer-left-${chapterId}`)
  // setTImeoutのタイミングは呼ばれる側のｙふんわりfadeと合わせてる
  setTimeout(() => {
    leftEl.dataset.textbookToken = json.attr.textbook_token
    leftEl.dataset.textbookChapterId = json.attr.chapter_id
    // leftEl.dataset.textbookSectionId = json.attr.prev.section_id
    leftEl.dataset.textbookArticleId = json.attr.prev.article_id
  }, 200)
  setTimeout(() => {
    if (!json.attr.prev.article_id) {
      leftEl.parentElement.classList.add("invisible")
      // leftEl.querySelector(".textbook-footer-text").innerHTML = null
    }
    if (json.attr.prev.article_id) {
      leftEl.parentElement.classList.remove("invisible")
      leftEl.parentElement.style.opacity = 1
      leftEl.parentElement.classList.add("textbook-fade-in")
      leftEl.querySelector(".textbook-footer-text").innerHTML = json.attr.prev.title
    }
  }, 400)

  let rightEl = document.getElementById(`js-textbook-footer-right-${chapterId}`)
  setTimeout(() => {
    rightEl.dataset.textbookToken = json.attr.textbook_token
    rightEl.dataset.textbookChapterId = json.attr.chapter_id
    // rightEl.dataset.textbookSectionId = json.attr.next.section_id
    rightEl.dataset.textbookArticleId = json.attr.next.article_id
  }, 200)

  setTimeout(() => {
    if (!json.attr.next.article_id) {
      rightEl.parentElement.classList.add("invisible")
      // rightEl.querySelector(".textbook-footer-text").innerHTML = null
    }
    if (json.attr.next.article_id) {
      rightEl.parentElement.classList.remove("invisible")
      rightEl.parentElement.style.opacity = 1
      rightEl.parentElement.classList.add("textbook-fade-in")
      rightEl.querySelector(".textbook-footer-text").innerHTML = json.attr.next.title
    }
  }, 400)
}

function textbook_arrowkey() {
  document.addEventListener("keydown", function (event) {
    this.querySelectorAll(".js-textbook-modal").forEach((modalEl) => {
      // display block 以外 なら何もしない
      if (modalEl.style.display !== "block") {
        return
      }
      if (event.key === "ArrowLeft") {
        // 左矢印キーを押した場合
        let btn = modalEl.querySelector(
          `.textbook-footer-left .js-textbook-footer-link:not(.invisible)`
        )
        if (btn) {
          btn.click()
        }
      }
      if (event.key === "ArrowRight") {
        // 右矢印キーを押した場合
        let btn = modalEl.querySelector(
          `.textbook-footer-right .js-textbook-footer-link:not(.invisible)`
        )
        if (btn) {
          btn.click()
        }
      }
    })
  })
}

function show_bookmark_manually() {
  var tailoredToggle = document.getElementById("tailored_toggle")
  var certListMain = document.getElementById("cert_list_main")
  var certListBookmark = document.getElementById("cert_list_bookmark")

  if (!tailoredToggle || !certListMain || !certListBookmark) {
    return
  }
  tailoredToggle.addEventListener("click", function () {
    if (certListMain.classList.contains("show") && certListMain.classList.contains("active")) {
      certListMain.classList.remove("show", "active", "fade-in")

      certListBookmark.style.opacity = 0
      certListBookmark.classList.add("show", "active", "fade-in")

      tailoredToggle.classList.add("active")
    } else {
      certListMain.style.opacity = 0
      certListMain.classList.add("show", "active", "fade-in")

      certListBookmark.classList.remove("show", "active", "fade-in")
      tailoredToggle.classList.remove("active")
    }
  })
}

function mobile_textbook_chapters() {
  var triggerElements = document.querySelectorAll(".mobile_chapter_trigger")
  var scrimElements = document.querySelectorAll(".mobile_chapter_trigger_scrim")

  triggerElements.forEach(function (triggerElement) {
    triggerElement.addEventListener("click", function () {
      document.querySelectorAll(".textbook-nav-steps").forEach(function (navStepsElement) {
        navStepsElement.classList.toggle("mct_open")
      })
      scrimElements.forEach(function (scrim) {
        scrim.classList.toggle("mct_open")
      })
    })
  })

  scrimElements.forEach(function (scrim) {
    scrim.addEventListener("click", function () {
      document.querySelectorAll(".textbook-nav-steps").forEach(function (navStepsElement) {
        navStepsElement.classList.toggle("mct_open")
      })
      scrim.classList.toggle("mct_open")
    })
  })

  function adjustPadding() {
    if (window.innerWidth < 800) {
      var navStepsElements = document.querySelectorAll(".mobile_chapter_trigger")
      var primaryTextareaElements = document.querySelectorAll(".textbook-primary-textarea")
      var totalNavStepsHeight = 0

      navStepsElements.forEach(function (element) {
        totalNavStepsHeight += element.offsetHeight
      })

      primaryTextareaElements.forEach(function (element) {
        element.style.paddingTop = totalNavStepsHeight + "px"
      })
    } else {
      document.querySelectorAll(".textbook-primary-textarea").forEach(function (element) {
        element.style.paddingTop = ""
      })
    }
  }
  adjustPadding()
  window.addEventListener("resize", adjustPadding)
  document.addEventListener("DOMContentLoaded", adjustPadding)
}
